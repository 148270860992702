
@media (max-width: 640px) {
  .story-header {
    height: 25px !important;
    background-color: #ececec !important;

    span {
      font-size: 0.6rem !important;
    }

    svg {
      height: 0.8em !important;
    }
  }
}

.story-card-container {
  overflow: hidden;
  .story-card{
    position: relative;
    contain: content;
    height: 100%;
    min-height: 22vw;
  }
  a:hover{
    text-decoration: none;
  }
  .story-card-info {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 10px;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 60%, rgba(0,0,0,0) 100%);
  }
  .story-image {
    height: 100%;
    background-color: $darker;
  }
  img {
    width: 100%;
  }
  .story-header {
    height: 50px;
    background-color: $background-item;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 3rem 1rem 3rem;
    span {
      font-family: 'AvenirNext-Regular';
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 1.37;
      color: $light;
      text-transform: uppercase;
    }

    
  }
  .story-metrics {
    position: relative;
    font-family: 'AvenirNext-Heavy';
    text-align: left;
    color: $white;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1em;
    line-height: 1.36;
    svg {
      fill: white;
      height: 19px;
      margin-left: 10px;
      margin-top: 2px;
    }
  }
  .story-details {
    bottom: 17px;
    margin-top: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    left: 23px;
    color: $lightest;
    text-align: left;
    .story-title {
      font-family: 'AvenirNext-Bold';
      font-size: 12px;
      font-weight: bold;
      line-height: 1.25;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .broadcast-details {
      font-family: 'AvenirNext-Medium';
      font-size: 11px;
      font-weight: 500;
      letter-spacing: 1.1;
    }
  }
}
