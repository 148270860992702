.space-y-form {
  @apply space-y-6;
}

.gap-y-form {
  @apply gap-y-6;
}

.space-y-form-tight {
  @apply space-y-4;
}

.gap-y-form-tight {
  @apply gap-y-4;
}
