.input-login-container {
  display: flex;
  align-items: center;

  .input-login {
    border-radius: 7px;
    background-color: #f2f2f2;
    font-family: $regular;
    font-size: 16px;
    border: 0;
    padding: 15px;
    padding-left: 45px;
    color: black;
  }

  i {
    position: absolute;
    padding-left: 15px;
  }
}

.input-search-component-wrapper {
  width: 400px;
  padding:  50px 30px;
  background-color: $background-nav;
  .input-search-container {
    display: flex;
    align-items: center;
    .input-search {
      background-color: $white;
      font-family: $regular;
      font-size: 16px;
      border: 0;
      padding: 10px;
      padding-left: 30px;
      width: 100%;
      color: $dark;
      outline: none;
    }
    .icon {
      fill: $mediumdark;
      height: 40px;
      margin-left: 20px;
    }
  }
}
