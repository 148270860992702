@font-face {
  font-family: "AvenirNext-Medium";
  src:
    url("../fonts/avenirnext/AvenirNext-Medium.woff2") format('woff2'),
    url("../fonts/avenirnext/AvenirNext-Medium.woff") format('woff');
}
@font-face {
  font-family: "AvenirNext-Regular";
  src:
    url("../fonts/avenirnext/AvenirNext-Regular.woff2") format('woff2'),
    url("../fonts/avenirnext/AvenirNext-Regular.woff") format('woff');
}
@font-face {
  font-family: "AvenirNext-Bold";
  src:
    url("../fonts/avenirnext/AvenirNext-Bold.woff2") format('woff2'),
    url("../fonts/avenirnext/AvenirNext-Bold.woff") format('woff');
}
@font-face {
  font-family: "AvenirNext-Heavy";
  src:
    url("../fonts/avenirnext/AvenirNext-Heavy.woff2") format('woff2'),
    url("../fonts/avenirnext/AvenirNext-Heavy.woff") format('woff');
}
