#homepage {
  background-color: #f1f1f1;
  height: inherit;

  .left-column {
    @media (max-width: 1024px) {
      padding: 20px;
    }

    @media (min-width: 1024px) {
      padding: 60px;
      padding-right: 20px;
    }
  }

  .right-column {
    background-image: url("/images/phenix_channels/homepage-channels.jpg");
    background-size: cover;

    /* Do not show right column on smaller screen */
    @media (max-width: 640px) {
      display: none;
    }
  }

  .logo {
    display: inline-block;
    width: 72px;
    height: 72px;
    padding: 2px;
    border-radius: 8px;
    background-color: $phenix-stories-logo-background;

    /* Phenix Channels text */
    + span {
      color: #707070;
      font-size: 22px;
      margin-left: 15px;
    }
  }

  .main-title {
    margin-top: 150px;

    font-family: "AvenirNext-Bold";
    font-size: 35px;
    line-height: 1.27;
    color: black;
    font-size: 60px;
  }

  .main-subtitle {
    line-height: 1.36;
    color: black;
    font-size: 22px;

    margin-top: 50px;
    margin-bottom: 50px;

    font-size: 28px;
  }
}
