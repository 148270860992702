.select-container {
  label.wrap {
    width:100px;
    overflow: hidden;
    position: relative;
    display: block;
    &:after {
      font-family: FontAwesome;
      content: "\f078" !important;
      font-size: 14px;
      color: $dark;
      position: absolute;
      right: 10px;
      top: 7px;
      z-index: 1;
      width: 10%;
      height: 100%;
      pointer-events: none;
    }
  }
  select {
    position: relative;
    outline: none;
    border: none;
    background: none;
    font-family: "AvenirNext-Regular";
    font-size: 14px;
    line-height: 1.36;
    color: $dark;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}