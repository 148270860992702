/* Avatar upload */

.avatar-upload {
  display: flex;
  justify-content: center;
}

.avatar-upload--image {
  width: 255px;
  height: 255px;

  &__rounded {
    border-radius: 50%;
  }
}

.avatar-upload--form {
  transition: background-color 0.5s;

  position: absolute;
  background-color: rgba(0, 0, 0, 0);

  height: 255px;
  width: 255px;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.avatar-upload--icon {
  margin-bottom: 0;
  transition: opacity 0.5s;
  padding: 100px;
  cursor: pointer;
  font-size: 50px;
  display: flex;
  opacity: 0.5;
  color: white;

  &:hover {
    opacity: 1;
  }
}

.avatar-upload--input {
  display: none !important;
}

/*  */

.avatar-container {
  display: flex;
  align-items: center;
  .avatar-circle {
    vertical-align: middle;
    border-radius: 50%;
    object-fit: cover;
  }
  .avatar-md {
    height: 85px;
    width: 85px;
  }
  .avatar-lg {
    height: 255px;
    width: 255px;
  }
  .avatar-xs {
    height: 82px;
    width: 82px;
  }
  .user-details {
    margin-left: 15px;
    .channel-name {
      display: block;
      font-family: "AvenirNext-Medium";
      font-size: 33px;
      font-weight: 600;
      color: $medium-default;

    }
    .story-count {
      font-family: "AvenirNext-Regular";
      font-size: 21px;
      line-height: 1.38;
      color: $light;
    }
  }
}
