.centered-layout {
  @apply mt-2;
}

/* equivalent md:... */
@media (min-width: 768px) {
  .centered-layout {
    @apply mx-auto;
    @apply mt-6;
    @apply max-w-6xl;
  }
}

.fullwidth-layout {
  @apply mt-2;
  @apply max-w-full;
}

/* equivalent md:... */
@media (min-width: 768px) {
  .fullwidth-layout {
    @apply mt-6;
  }
}
