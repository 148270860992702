#rank-page {
  .story-rank-header {
    margin: 0 20px;
    align-items: center;
    label {
      font-size: 18px;
    }
  }
  .phenix-rank-container {
    background-color: #f8f8f8;
    padding: 0px 20px;
    margin: 15px 15px 0 15px;
  }
  .col-xl-2-4 {
    @media (min-width: 1200px) {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
  .stories-rank {
    &:hover {
      cursor: pointer;
    }
    .story-card-container {
      box-shadow: 0px 0px 0px 3px #e1e1e1 inset;
      background-size: 70px;
      background-repeat: no-repeat;
      background-position: center;
    }
    .story-card {
      min-height: inherit;
      margin-bottom: 30px;
    }
    .story-image {
      height: calc(100% - 85px);
    }
    .stats {
      align-items: center;
      height: 55px;
      .rank {
        padding-right: 10px;
        border-right: solid 2px $mediumlight;
      }
      .details {
        padding-left: 15px;
        .story-views {
          color: $medium-default;
          font-size: 14px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .story-title {
          color: $darker;
          font-size: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  .ranking-interval {
    border: solid 2px $tertiary;
    border-radius: 15px;
    padding: 3px 9px 3px 9px;
    i {
      font-size: 18px;
    }
    label {
      font-size: 16px;
    }
  }
  .modal {
    .modal-video {
      height: initial;
      width: fit-content;
      margin: auto;
    }
    .arrow-icon {
      color: white;
      font-size: larger;
      padding: 0 20px;
      &:hover {
        cursor: pointer;
        color: $secondary;
      }
    }
    video {
      display: block;
      height: 80vh;
      width: 45vh;
      opacity: 1;
    }
  }
  .modal-backdrop {
    opacity: 0.95;
    background-color: $darkest;
  }
}
