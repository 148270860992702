.tagify {
  --tag-text-color: white;
  --tag-bg: #27b6af; /* primary-btn */
  --tag-hover: #1e8c87; /* primary-btn-hover */
  --tag-remove-btn-color: white;
  --tag-remove-btn-bg--hover: #fee2e2; /* bg-danger-bg-hover */
  --tag-remove-bg: #ef4444; /* bg-danger-btn */
  --tags-focus-border-color: #e5e7eb; /* border-default-border */

  .disabled {
    opacity: 0.7;
  }

  .tag-disabled .tagify__tag-text {
    text-decoration: line-through;
  }
}

.theme-displays .tagify--select {
  height: 40px;
}

.theme-riders .tagify--select {
  height: 48px;
}

/* V icon color at the right end */
.tagify--select::after {
  @apply text-default-txt-informative;
  font-family: "Font Awesome 5 Pro";
  content: "\f054";
  font-size: 13px;
  opacity: 1;
  right: 5px;
}

/* without this hack, there is a strange white strikethrough */
/* in the middle on the tag (on hover) */
.tagify__tag:focus div:before,
.tagify__tag:hover:not([readonly]) div:before {
  top: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  right: 0px !important;
}

.tagify--select .tagify__tag__removeBtn {
  background-color: var(--color-gray-200) !important;
}

.tagify--select .tagify__tag__removeBtn:hover {
  background-color: var(--color-red-300) !important;
}

.tagify--empty .tagify__input:before {
  transition: none !important;
}

/* vertical centering of the placeholder */
.tagify__input::before {
  line-height: 19px;
  height: 19px;
  @apply text-default-txt-informative;
}

.tagify__input {
  overflow: hidden;
  height: 28px;
  @apply text-sm;
  @apply text-default-txt;
}

.tagify__input:disabled {
  @apply text-default-txt-informative;
}

.tagify__input:read-only {
  @apply text-default-txt-informative;
}

tags.form-input-error {
  @apply border-danger-border hover:border-danger-border text-danger-txt;
  .tagify__input::before {
    @apply text-danger-txt-secondary;
    opacity: 0.7;
  }
}

tags.form-input,
tags.phx-no-feedback.form-input-error,
.phx-no-feedback tags.form-input-error {
  @apply border-default-border hover:border-default-border text-default-txt;
  .tagify__input::before {
    @apply text-default-txt-informative;
  }
}

.tagify__dropdown {
  margin-top: -4px;

  .tagify__dropdown__wrapper {
    @apply rounded-b-md;
    @apply border-default-border;
    padding: 5px 1px 1px;
  }

  .tagify__dropdown__item {
    @apply text-sm;
    @apply text-default-txt;
    @apply rounded-md;
    padding: 0.6em 0.5em;

    &:active {
      filter: brightness(100%);
    }

    &--active {
      color: white;
      @apply bg-primary-btn;
    }
  }
}

.theme-riders {
  .tagify__input::before {
    line-height: 28px;
    height: 28px;
  }

  .tagify__input {
    height: 36px;
    line-height: 28px;
    @apply text-base;
  }

  .tagify__input:disabled {
    @apply text-default-txt-informative;
  }

  .tagify__input:read-only {
    @apply text-default-txt-informative;
  }

  .tagify__dropdown {
    .tagify__dropdown__item {
      @apply text-base;
    }
  }

  .tagify__tag {
    margin: 7px 0px 3px 8px;
  }

  .tagify__dropdown {
    margin-top: -8px;

    .tagify__dropdown__wrapper {
      @apply rounded-b-xl;
    }

    .tagify__dropdown__item {
      @apply rounded-xl;
    }
  }
}
