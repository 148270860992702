.icon-container {
  display: flex;
  align-items: center;
  margin-right: 10px;
  .icon {
    height: 25px;
    fill: #707070;
  }
  .label {
    font-size: 19px;
    font-family: "AvenirNext-Medium";
    font-weight: 500;
    color: #707070;
    margin-left: 15px;
  }
  .notification {
    width: 10px;
    height: 10px;
    background: #281c99;
    border-radius: 50%;
    align-self: flex-start;
    left: 0;
    top: 0;
  }
}