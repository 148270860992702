$max_smartphone_size: 640px;

#app-channels .mobile-off {
  @media (max-width: 640px) {
    display: none !important;
  }
}

#app-channels {
  .sidebar-container {
    position: relative;

    .sidebar-header {
      height: 70px;
      display: flex;
      align-items: center;
      
      .logo {
        margin-left: 10px;
        p {
          font-size: 20px;
          font-family: "AvenirNext-Bold";
          letter-spacing: 1.05px;
        }
      }
    }
    .sidebar-item {
      width: inherit;
      display: block !important;
    }
  }

  .central {
    width: 100%;
    overflow: auto;
  }

  header {
    height: 70px;
    background-color: $background-nav;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  header.mobile-on {
    background-color: #ececec !important;
  }
}

#app-channels {
  @media (max-width: 640px) {
    .mobile-on {
      display: block;
    }
  }

  @media (min-width: 640px) {
    .mobile-on {
      display: none !important;
    }
  }

  @media (max-width: 1024px) {
    .small-screen-menu {
      display: block;
    }
    .sidebar-container {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    .small-screen-menu {
      display: none;
    }
    .sidebar-container {
      display: block;
    }
  }
}

.avatar-container img {
  object-fit: cover;
}
