body.theme-displays,
.psb-sandbox.theme-displays {
  font-family: "OpenSans", sans-serif;
  @apply text-base font-normal text-default-txt;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Montserrat", sans-serif;
  }

  h1 {
    @apply text-3xl font-bold text-primary-txt my-6;
  }
  h2 {
    @apply text-2xl font-bold my-4;
  }
  h3 {
    @apply text-xl font-bold my-2;
  }
  h4 {
    @apply text-lg font-bold my-2;
  }
  h5 {
    @apply text-base font-bold my-2;
  }

  /* 
    seagreen colors
    https://www.tailwindshades.com/#color=27B6AF&step-up=11&step-down=10&name=seagreen
  */
  --color-seagreen: #27b6af;
  --color-seagreen-50: #e1f9f7;
  --color-seagreen-100: #caf4f2;
  --color-seagreen-200: #9ceae6;
  --color-seagreen-300: #6de0da;
  --color-seagreen-400: #3fd6cf;
  --color-seagreen-500: #27b6af;
  --color-seagreen-600: #1e8c87;
  --color-seagreen-700: #15625e;
  --color-seagreen-800: #0c3836;
  --color-seagreen-900: #030e0d;

  /* primary colors */
  --color-primary: #27b6af;
  --color-primary-txt: #27b6af;
  --color-primary-txt-secondary: #3fd6cf;
  --color-primary-bg: #e1f9f7;
  --color-primary-bg-hover: #caf4f2;
  --color-primary-btn: #27b6af;
  --color-primary-btn-hover: #1e8c87;
  --color-primary-btn-focus: #27b6af;
  --color-primary-btn-disabled: #7bccc8;

  .main-container {
    @apply bg-white;
  }
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/Montserrat-Regular.woff2") format("woff2"),
    url("../fonts/montserrat/Montserrat-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat/Montserrat-Bold.woff2") format("woff2"),
    url("../fonts/montserrat/Montserrat-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/opensans/OpenSans-Light.woff2") format("woff2"),
    url("../fonts/opensans/OpenSans-Light.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/opensans/OpenSans-Light.woff2") format("woff2"),
    url("../fonts/opensans/OpenSans-Light.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/opensans/OpenSans-Light.woff2") format("woff2"),
    url("../fonts/opensans/OpenSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/opensans/OpenSans-Regular.woff2") format("woff2"),
    url("../fonts/opensans/OpenSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/opensans/OpenSans-Regular-Italic.woff2") format("woff2"),
    url("../fonts/opensans/OpenSans-Regular-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/opensans/OpenSans-Regular.woff2") format("woff2"),
    url("../fonts/opensans/OpenSans-Regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/opensans/OpenSans-SemiBold.woff2") format("woff2"),
    url("../fonts/opensans/OpenSans-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/opensans/OpenSans-Bold.woff2") format("woff2"),
    url("../fonts/opensans/OpenSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/opensans/OpenSans-ExtraBold.woff2") format("woff2"),
    url("../fonts/opensans/OpenSans-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("../fonts/opensans/OpenSans-ExtraBold.woff2") format("woff2"),
    url("../fonts/opensans/OpenSans-ExtraBold.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
