.riders-breadcrumb {
  li {
    float: left;
    &:hover {
      background-color: #b093f5;
    }
    a {
      position: relative;
      line-height: 35px;
      padding: 0 10px 0 5px;
      text-align: center;
      margin-right: 20px;
    }

    &:first-child {
      a {
        padding-left: 15px;
        &:before {
          border: none;
        }

        &:before {
          left: -20px;
          border-left-color: transparent;
        }

        &:after {
          content: "";
          position: absolute;
          z-index: 20;
          top: 0;
          right: -20px;
          border-width: 18px 10px 17px 10px;
          border-color: white white white #e1d4ff;
          width: 0;
          height: 0;
        }

        &:hover {
          background-color: #d9d6f7;

          &:before {
            border-color: white white white #d9d6f7;
          }
          &:after {
            border-color: white white white #d9d6f7;
          }
        }
      }
    }

    &:not(:first-child) {
      a {
        background-color: white;
        color: #707070;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          border-width: 18px 10px 17px 10px;
          border-color: white white white white;
          width: 0;
          height: 0;
          left: -20px;
        }

        &:after {
          content: "";
          position: absolute;
          z-index: 20;
          top: 0;
          right: -20px;
          border-width: 18px 10px 17px 10px;
          border-color: white white white white;
          width: 0;
          height: 0;
        }

        &:hover {
          background-color: #d9d6f7;

          &:before {
            z-index: 30;
            border-color: #d9d6f7 #d9d6f7 #d9d6f7 white;
            border-left-color: transparent;
          }

          &:after {
            content: "";
            position: absolute;
            z-index: 20;
            top: 0;
            right: -20px;
            border-width: 18px 10px 17px 10px;
            border-color: white white white #d9d6f7;
            width: 0;
            height: 0;
          }
        }
      }
    }

    &:last-child {
      a {
        color: red;

        &:after {
          content: "";
          position: absolute;
          z-index: 20;
          top: 0;
          right: -20px;
          border-width: 18px 10px 17px 10px;
          border-color: white white white #e1d4ff;
          width: 0;
          height: 0;
        }

        &:hover {
          background-color: #d9d6f7;

          &:after {
            border-color: white white white #d9d6f7;
          }
        }
      }
    }
  }
}

.riders-breadcrumb {
  li:last-of-type {
    a {
      &:after {
        content: "";
        position: absolute;
        z-index: 20;
        top: 0;
        right: -20px;
        border-width: 18px 10px 17px 10px;
        width: 0;
        height: 0;
      }

      &:hover {
        background-color: #d9d6f7;

        &:after {
          border-color: #d9d6f7 #d9d6f7 #d9d6f7 #d9d6f7;
        }
      }
    }
  }
}
