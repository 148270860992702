/*Disable ANimation on load*/
.preload .switch-dot {
  animation: none !important;
}

.toggle-switch-container {
  position: relative;
  width: 130px;

  .switch-input {
    display: none;
  }

  /*Style words and oval switch */

  .switch-labels {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border-radius: 20px;
  }

  .switch-text {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before, &:after {
      float: left;
      width: 50%;
      line-height: 30px;
      color: white;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-label);
      padding-left: 15px;
      background-color: $primary;
      color: $white;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 0.45px;
    }
    &:after {
      content: attr(data-label);
      padding-right: 15px;
      background-color: $lightest;
      color: $light;
      text-align: right;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 0.45px;
    }
    &:hover:before {
      background-color: rgba($primary, .8);
    }
    &:hover:after {
      background-color: rgba($lightest, .8);
    }
  }

  /*Style center dot*/

  .switch-dot {
    width: 30px;
    height: 30px;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -1px;
    right: auto;
    margin-right: 5px;
    border-radius: 20px;
    transition: left 0.3s ease-in 0s;
    &:after {
      content: attr(data-unchecked);
      color: $light;
      text-align: center;
      position: absolute;
      top: 8px;
      left: 5px;
      width: 20px;
      height: 20px;
      font-size: 10px;
      text-transform: uppercase;
    }
  }

  /*State changer*/

  .switch-input:checked+.switch-labels .switch-text {
    margin-left: 0;
  }

  .switch-input:checked+.switch-labels .switch-dot {
    right: -1px;
    left: calc(100% - 29px);
    margin-right: 0px;
    &:after {
      content: attr(data-checked);
      font-size: 10px;
      color: $primary;
      text-transform: uppercase;
    }
  }

  .switch-input:disabled+.switch-labels {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .switch-input:disabled+.switch-labels .switch-text:hover:before {
    background-color: $primary;
  }

  .switch-input:disabled+.switch-labels .switch-text:hover:after {
    background-color: $lightest;
  }

}
