.sidebar-container {
  width: 255px;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: $background-nav;
  .sidebar-header {
    background-color: $primary;
    height: 70px;
    display: flex;
    img {
      margin: auto;
      height: 60px;
    }
  }
  .sidebar {
    width: 255px;
    background-color: $background-nav;
    border-bottom: solid 1px $lighter;
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style-type: none;
        width: 100%;
        margin: 0;
        padding: 0;
        .sidebar-item.active {
          color: $primary;
          background-color: $background-nav-active;
          border-left: solid 6px $primary !important;
        .item-content {
          margin-left: -6px;
          height: 20px;
        }
      }
        .sidebar-item {
          display: flex !important;
          padding: 20px 40px;
          color: $mediumdark;
          padding: 20px 40px;
          text-decoration: none;
          display: block;
          text-align: left;
          font-family: $regular;
          font-size: 16px;
          .item-content {
            display: flex;
            align-items: center;
            height: 20px;
          }
          .icon {
            width: 20px;
            margin-right: 25px;
            fill: $mediumdark;
          }
          &:hover {
            color: $mediumlight;
            background-color: $background-item;
            .icon {
              fill: $mediumlight;
            }
          }
        }
      }
    }
  }
}
