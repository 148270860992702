.ranking-card,
.global-analytics-card,
.global-views-card {
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.03);
  background-color: $background-item;
  border: none;
}

#analytics-view {
  .total-stories-card {
    box-shadow: 0 25px 40px 0 rgba(0, 0, 0, 0.05);
  }
  .most-viewed-story-container,
  .ranking-card,
  #stories-displays-map,
  .story-card-container,
  .empty-map-container {
    height: 427px !important;
  }
  .most-viewed-story-container {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.35;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $medium-default;
  }
  .sub-title {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: left;
    color: $medium-default;
  }
  .global-views-card {
    min-height: 300px;
  }
  .ranking-card {
    .ranking-stories {
      height: inherit;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
  .top-videos-views {
    .story-title,
    .story-views {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.39;
      letter-spacing: normal;
      text-align: left;
      width: 100%;
    }
  }
  .chartjs-size-monitor {
    height: inherit;
  }

  .story-card-container {
    width: auto;
    img {
      height: 377px;
      width: auto;
    }
  }

  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .map-container {
    background-color: #e0e0e0;
    position: relative;
    margin: 0px 0px 25px 0px;
    height: 50%;

    @media (max-width: 768px) {
      display: none;
    }

    @media (max-width: 900px) {
      height: 100%;
    }
    .empty-map-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .error-message {
        padding: 20px;
        background-color: rgba(255, 255, 255, 0.5);
        .fa {
          color: $danger;
          margin-right: 15px;
        }
        .text {
          color: $darker;
        }
      }
    }

    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
      z-index: 1;
      .text {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary;
        span {
          background: rgba(255, 255, 255, 0.8);
          padding: 15px 20px;
        }
      }
    }
  }

  .story-count {
    font-size: 15px;
    margin-top: 10px;
  }

  .single-analytic {
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'AvenirNext-Bold';
    color: $primary;
    display: flex;
    align-items: center;

    svg {
      display: inline-block;
      text-transform: lowercase;
      width: 21px;
      margin-right: 5px;
    }
  }
}

.views-logo {
  background-color: #eeedfd;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  .square {
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #281c99;
    border-radius: 3px;
    width: 18px;
    height: 18px;
    .logo {
      font-size: 11px;
      align-self: center;
      position: relative;
      right: -1px;
    }
  }
}

.channel-analytics-mobile {
  padding: 0.5em;

  .card-analytics,
  .story-card-container {
    margin: 0.5em;
  }

  .card-analytics {
    width: 50em;
    background-color: $background-item;
    font-size: 0.7em;

    .title {
      font-size: 1.1em;
      font-family: 'AvenirNext-Bold';
    }
  }

  .total-analytics {
    color: $primary;
    font-family: 'AvenirNext-Bold';
    text-transform: uppercase;
    font-size: 0.75em;

    svg {
      display: inline-block;
      margin-right: 0.5em;
      text-transform: none;
      width: 25px;
    }
  }

  .views-logo {
    min-width: 20px;
    height: 20px;
    .square {
      width: 11px;
      height: 11px;
      .logo {
        font-size: 6px;
        right: -1px;
      }
    }
  }
}

.ranking-card .title {
  @media (max-width: 1024px) {
    font-size: 0.8em;
    font-family: 'AvenirNext-Bold';
  }
}
