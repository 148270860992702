.ranking-story {
  background-color: $white;
  &:hover {
    border-left: solid 6px $primary;
  }
  a:hover {
    text-decoration: none;
  }
  .rank-container {
    &:hover {
      margin-left: -6px;
      .icon-wrapper {
        background-color: #eeedfd !important;
        svg {
          fill: $primary !important;
        }
      }
    }
    height: 65px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.02);
    display: flex;
    align-items: center;
    .rank-number {
      font-family: 'AvenirNext-Medium';
      font-size: 25px;
      font-weight: 600;
      line-height: 1.44;
      color: $primary;
      text-align: center;
      width: 15%
    }
    .story-details {
      width: 50%;
      .start-date {
        font-family: 'AvenirNext-Medium';
        font-size: 10px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: 0.4px;
        color: $lighter;
      }
      .story-rank-title {
        font-family: 'AvenirNext-Regular';
        font-size: 13px;
        line-height: 1.38;
        letter-spacing: 0.15px;
        color: $darker;
      }
    }
    .story-stat {
      width: 20%;
      text-align: right;
      font-family: 'AvenirNext-Regular';
      font-size: 10px;
      line-height: 1.38;
      letter-spacing: 0.15px;
      color: $darker;
      span {
        font-family: 'AvenirNext-Heavy';
        font-size: 13px;
        line-height: 1.08;
        letter-spacing: -0.08px;
        color: $darker;
      }
    }
    .icon {
      width: 15%;
      .icon-wrapper {
        width: 24px;
        height: 24px;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 25px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 15px;
          fill: $white;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
