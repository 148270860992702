/* Range */
input[type="range"].storybook-range {
  -webkit-appearance: none;
  @apply h-10;
  @apply w-full;
  background: transparent;

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    width: 100%;
    height: 12px;
    cursor: pointer;
    @apply bg-default-bg-hover;
    @apply rounded;
    @apply border-default-border;
    box-shadow: none;
  }

  &::-moz-range-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    @apply bg-default-bg-hover;
    @apply rounded;
    @apply border-default-border;
    box-shadow: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    @apply border-default-border;
    @apply bg-primary-btn;
    margin-top: -4px;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    box-shadow: none;
  }

  &::-moz-range-thumb {
    cursor: pointer;
    @apply border-default-border;
    @apply bg-primary-btn;
    margin-top: -4px;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    box-shadow: none;
  }

  &:hover::-webkit-slider-thumb {
    @apply bg-primary-btn-hover;
  }

  &:hover::-moz-range-thumb {
    @apply bg-primary-btn-hover;
  }

  &:focus {
    outline: none;
  }

  &:focus::-webkit-slider-thumb {
    @apply outline-none;
    @apply ring-2;
    @apply ring-offset-2;
    @apply ring-primary-btn-focus;
  }

  &:focus::-moz-range-thumb {
    @apply outline-none;
    @apply ring-2;
    @apply ring-offset-2;
    @apply ring-primary-btn-focus;
  }
}
