#video-page {
  .top-container {
    align-items: center;
    justify-content: space-between;
  }

  #stories-container {
    @media (max-width: 768px) {
      margin: 0 10px;
    }
  }
  
  .mobile > #channel-stats, .story-card-container {
    width: 40%;
  }


  .stats-and-filter-container {

    .stats-container {
      justify-content: flex-end;
      
      @media (max-width: 768px) {
        display: none;
      }
      
      .stats-container {
        justify-content: flex-end;
      
        .view-icon {
          color: $primary;
          font-size: 20px;
        }
      }
      
    }
  }

  .filters-container {
    align-items: center;
    justify-content: space-between;

    @media (min-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
    }

    .label {
      font-family: "AvenirNext-Bold";
      font-size: 9px;
      color: #7e7e7e;
    }
    .toggle-switch-container {
      @media (max-width: 768px) {
        margin-left: auto;
      }
    }
    .toggle-switch-container .switch-text::after {
      background-color: #e3e3e3;
    }

    #broadcasting-switch-container {
      width: 135px;
    }

    #unselected-switch-container {
      width: 110px;
    }
  }

  .channel-audience {
    color: $primary;
    font-family: "AvenirNext-Bold";
  }

  .channel-audience-label {
    color: $primary;
    font-family: "AvenirNext-Bold";
    font-size: 13px;
    text-transform: uppercase;
    align-self: center;
  }

  .story-count {
    color: $primary;
    font-weight: bold;
    align-items: center;
    img {
      height: 25px;
    }

    svg {
      height: 30px;
      width: 30px;
      overflow: visible;
      transform: scale(0.8);
      fill: $primary;
      margin-left: -8px;
    }
  }

  .header-date-picker {
    flex-direction: row-reverse;
    font-size: 14px;
    font-weight: bold;
  }

  .icon.broadcasting {
    transform: rotate(45deg);
  }

  .icon.view {
    fill: $primary;
  }

  .icon-container .label {
    font-size: 14px;
    font-family: "AvenirNext-Bold";
  }

  .last-broadcasting-story-with-map {
    border-top: 3px solid red;
  }

  .last-broadcasting-story {
    width: min-content;
    @media (min-width: 1200px) {
      min-width: 25%;
    }
    @media (max-width: 1200px) {
      min-width: 40%;
    }
    .story-card-info {
      bottom: 0px;
      height: 150px;
      position: absolute;
    }

    .icon-container svg {
      fill: red;
    }
  }
  .story-header {
    .icon {
      height: 18px;
    }
  }
  .border-primary {
    border: solid 3px #281c99 !important;
  }
  .map-container {
    background-color: #e0e0e0;
    position: relative;
    margin: 0px 0px 25px 0px;
    height: 50%;
    overflow: hidden;

    @media (max-width: 768px) {
      display: none;
    }

    @media (max-width: 900px) {
      height: 100%;
    }

    #stories-displays-map {
      height: 100%;
    }

    .empty-map-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .error-message {
        padding: 20px;
        background-color: rgba(255, 255, 255, 0.5);
        .fa {
          color: $danger;
          margin-right: 15px;
        }
        .text {
          color: $darker;
        }
      }
    }

    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0,0,0,0.4);
      z-index: 1;
      .text {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary;
        span {
          background: rgba(255,255,255, 0.8);
          padding: 15px 20px;
        }
      }
    }
  }
}

.channel-stats-mobile {
  color: $mediumlight;
  font-size: 0.45em;

  svg {
    width: 15px;
    fill: $mediumlight;
    display: inline-block;
  }

  .bold {
    font-family: "AvenirNext-Bold";
    color: $primary;
  }
}

.stories-list {
  padding: 5px;

  .story-card-container, .card {
    margin: 5px;
  }

  .viewers-reached {
    color: #7f7e77;
    font-size: 0.5em;
    font-family: "AvenirNext-Bold";
  }

  .w-30 {
    width: 30%;
  }

  .card.darker {
    background-color: #ececec;
    border-radius: 0;
  }

  .last-broadcasting {
    .story-header {
      border-top: 3px solid red;
    }
  }
}
