/* Colors */
$primary: #281c99;
$primaryWithOpacity: #281c99bf;
$secondary: #f00672;
$tertiary: #dee2ec;

/* Monochrome */
$white: #ffffff;
$lightest: #efefef;
$lighter: #b7bacf;
$light: #7f7e77;
$mediumlight: #707070;
$medium-default: #545454;
$mediumdark: #474747;
$dark: #393939;
$darker: #35384d;
$darkest: #1a1a1a;
$black: #000000;

/* Status */
$success: #26e7ad;
$warning: #F6C744;
$danger: #FF5666;

/* Backgrounds */
$background-app: #f1f1f1;
$background-nav: #f6f6f6;
$background-nav-active: #efefef;
$background-item: #f8f8f8;

 /* Logo */
$phenix-stories-logo-background: #281c99;

/* tailwind color variables */
:root {
  --color-primary: $primary;
  --color-primary-opacified: $primaryWithOpacity;
  --color-secondary: $secondary;
  --color-success: $success;
  --color-warning: $warning;
  --color-danger: $danger;
}