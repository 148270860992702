html, body {
  height: 100%;
  width: 100%;
  min-height: 100vh;
}

body {
  font-family: "AvenirNext-Regular";
}

#container {
  min-height: 100vh;
  min-width: 100wh;
}

.topbar-container {
  .searchbar {
    input {
      background-color: white;
      cursor: not-allowed;
      border: none;
      width: 50%;
    }
    .search-icon {
      font-size: 14px;
    }
  }
  .dropdown-container {
    min-height: 0px !important;
    &:hover {
      cursor: pointer;
    }

    .dropdown-menu {
      padding: 0;
      margin: 0;
    }

    .dropdown-icon {
      font-size: 15px;
    }

    .name {
      font-family: "AvenirNext-Bold";
    }

    .dropdown-item {
      .item-icon {
        margin-right: 10px;
      }
    }
  }


  &.mobile-on {
    .dropdown-menu-right {
      right: -24px;
    }
  }

  .avatar-container {
    height: 60px;
    
    img {
      height: 45px;
      width: 45px;
      min-height: 45px;
      min-width: 45px;
    }
    .label-icon {
      width: 15px;
    }
  }

  .mobile-menu-icon {
    color: #707070;
  }

  .channel-summary {
    width: 75%;
  }

  .channel-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.bold {
      font-family: "AvenirNext-Bold";
    }
  }

  .channel-stories-count {
    font-size: 0.75em;
    color: $primary;

    svg {
      height: 23px;
      width: 27px;
      overflow: visible;
      transform: scale(0.6);
      fill: $primary;
      margin-left: -9px;
      margin-top: -7px;
      margin-right: 3px;
      display: inline-block;
    }
  }
}


.central {
  box-shadow: inset 6px -1px 43px -6px rgba(166,166,166,0.2);
}

#channel-stories-main-wrapper {
  overflow: auto;
  height: calc(100% - 70px);
  @media (min-width: 640px) {
    padding: 2rem;
    padding-top: 1rem;
  }
  @media (max-width: 992px) {
    padding: 1.5rem;
  }
  @media (max-width: 640px) {
    padding: 0rem;
  }
}

.sidebar-header {
  .logo {
    .appname-container {
      display: flex;
      flex-direction: column;

      .name {
        font-family: "AvenirNext-Bold";
      }
    }

    .beta-tag {
      font-size: 14px;
    }
  }
}

.sidebar{
  .item-content{
    svg {
      height: 25px;
      width: 25px;
    }
    .square {
      display: flex;
      justify-content: center;
      text-align: center;
      background-color: #545454;
      border-radius: 3px;
      width: 25px;
      height: 25px;
      &.active{
        background-color: #281c99;
      }
      .logo{
        align-self: center;
        position: relative;
      }
      .fa-chart-bar {
        font-size: normal;
      }
      .fa-play {
        font-size: small;
        right: -1px;
      }
    }
    .soon-tag {
      font-size: 12px;
      color: #707070;
      font-style: italic;
    }
  }
}
