$heavy: "AvenirNext-Heavy";
$bold: "AvenirNext-Bold";
$medium: "AvenirNext-Medium";
$regular: "AvenirNext-Regular";

h1 {
  font-family: $bold;
  font-weight: bold;
  font-size: 79px;
  line-height: 1.27;
  color: $black;
}

h2 {
  font-family: $medium;
  font-weight: '500';
  font-size: 33px;
  line-height: 1.36;
  color: $black;
}

body {
  font-family: $regular;
  font-size: 20px;
  line-height: 1.36;
  color: $medium-default;
}

p {
  font-family: $regular;
  font-size: 18px;
  line-height: 1.67;
  color: $medium-default;
}

.background-app {
  background-color: $background-app;

  @media (max-width: 640px) {
    background-color: white !important;
  }
}

.separator {
  border-bottom: 2px solid #e1e1e1;
  margin: 1rem 0px;

  &__no-margin {
    margin: 0;
  }
}

.h-inherit {
  height: inherit;
}

.contain-content {
  contain: content;
}

.overflow-scroll {
  overflow: scroll;
}

.no-border {
  border: none;
}

.white {
  color: $white;
}

.text-primary {
  color: $primary !important;
}
.text-secondary {
  color: $secondary !important;
}

.text-tertiary {
  color: $tertiary !important;
}

.text-title {
  color: $medium-default;
}

.text-subtitle {
  color: $mediumlight;
}

.text-content {
  color: $light;
}

.text-large {
  font-size: larger;
}

.text-xxlarge {
  font-size: xx-large;
}

.font-heavy {
  font-family: $heavy;
}

.font-bold {
  /* hardcoded because of an issue with postcss-advanced-variables
  which doesn't recognize the variable $bold */
  font-family: "AvenirNext-Bold";
}

.font-medium {
  font-family: $medium;
}

.font-regular {
  font-family: $regular;
}
