#user-profile {
  > div {
    height: inherit;
  }

  background-color: $primary;
  color: white;

  .flash-message {
    margin-bottom: 15px;
    .alert {
      margin: 0;
    }
  }

  .top-container {
    display: flex;
    height: 70px;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;

    .go-back, .toggle-edit {
      color: white;
      text-decoration: none;
    }

    .toggle-edit {
      i {
        margin-right: 15px;
      }
    }
  }

  .edit-password-top-container {
    justify-content: flex-start;
  }

  .avatar-container {
    flex-direction: column;
  }

  .profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 70px);
    align-items: center;
    input {
      color: $medium-default;
    }

    .profile {
      text-align: center;
      margin: 0 0 50px 0;
    }

    .name {
      font-family: 'AvenirNext-Bold';
      margin-top: 15px;
      font-size: 26px;
    }

    .email {
      margin-top: 20px;
      font-size: 15px;
    }

    .stats {
      text-align: center;

      .story-icon {
        margin-bottom: 15px;
        width: 35px;
      }
    }
  }

  .edit-profile-form {
    flex-direction: column;
    height: 200px;
    justify-content: space-evenly;

    .name {
      margin-top: 0;
    }

    .button-container {
      align-self: center;

      /* Submit button */
      button {
        color: white;
        border: none;
      }
    }

    input[type="text"] {
      border-radius: 10px;
      padding: 0px 15px 0px 15px;
      border: none;
      height: 50px;
    }
  }

  .edit-password-container {
    display: flex;
    align-items: center;
    justify-content: center;

    height: calc(100% - 70px);
    background-color: $background-app;

    form {
      font-size: 17px;
      color: #545454;
      display: flex;
      flex-direction: column;
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.05);
      background-color: #f6f6f6;
      padding: 50px 75px 50px 75px;

      label {
        margin: 20px 0 10px 0;
      }

      input {
        background-color: transparent;
        border: 0;
        border-bottom: solid 1px #e1e1e1;
        letter-spacing: 5px;
        color: #545454;
      }

      .hidden-email {
        display: none;
      }

      .password-info {
        margin: 35px 0 15px 0;
        font-size: 15px;
      }

      .password-submit {
        margin-top: 40px;
        background-color: $primary;
        border: 0;
        font-size: 16px;
        border-radius: 25px;
        padding: 10px 0 10px 0;
        max-width: 230px;

        &:hover {
          background-color: lighten($primary, 10%);
        }
      }

      .help-block {
        color: $danger;
        margin-top: 15px;
      }
    }
  }

  .edit-password-header {
    font-size: 18px;
    margin-left: 20px;
    opacity: 0.8;
  }

  .upload-result {
    position: absolute;
    margin-top: 50px;

    .success {
      color: #2ace2a;
    }

    .error {
      color: red;
    }
  }

  .avatar-upload--image {
    object-fit: cover;
  }
}

@media (max-width: 640px) {
  #user-profile .top-container .toggle-edit {
    i {
      margin-right: 5px;
    }
    font-size: medium;
  }
}