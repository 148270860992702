.button-container {
  .btn {
    font-family: "AvenirNext-Medium";
    font-size: 18px;
    font-weight: 500;
    line-height: 1.35;
    text-align: center;
    padding: 11px 45px;
    cursor:pointer;
    outline: none;
    white-space: nowrap;
  }
  .btn-primary {
    border-radius: 25px;
    color: $white;
    background-color: $primary;
  }
  .btn-secondary {
    border-radius: 25px;
    color: $mediumlight;
    border: solid 2px $tertiary;
  }
  .btn-sm {
    padding: 10px 34px;
    font-weight: 500;
  }
  .btn-lg {
    padding: 14px 69px;
    font-weight: 600;
  }
  button:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
  button.btn-primary:active {
    background-color: rgba( $primary, .8 );
  }
  button.btn-secondary:active {
    color: rgba($mediumlight, .6);
  }
  .btn-value-wrapper {
    display: flex;
    align-items: center;
    img {
      height: 25px;
      margin-right: 15px;
    }
  }
}