body.theme-riders,
.psb-sandbox.theme-riders {
  font-family: "AvenirNext", sans-serif;
  @apply text-base font-normal text-default-txt;

  h1 {
    @apply text-3xl font-bold text-primary-txt my-6;
  }
  h2 {
    @apply text-2xl font-bold text-default-txt my-4;
  }
  h3 {
    @apply text-xl font-bold text-default-txt my-2;
  }
  h4 {
    @apply text-lg font-bold text-default-txt my-2;
  }
  h5 {
    @apply text-base font-bold text-default-txt my-2;
  }

  /* 
  Rider colors
  https://www.tailwindshades.com/#color=3324C4&step-up=10&step-down=10&name=electricblue
  */
  --color-electricblue: #3324c4;
  --color-electricblue-50: #d9d6f7;
  --color-electricblue-100: #c5c0f4;
  --color-electricblue-200: #9d95ec;
  --color-electricblue-300: #766ae4;
  --color-electricblue-400: #4e3fdc;
  --color-electricblue-500: #3324c4;
  --color-electricblue-600: #281c99;
  --color-electricblue-700: #1d146e;
  --color-electricblue-800: #110c43;
  --color-electricblue-900: #060418;

  /* primary colors */
  --color-primary: #3324c4; /* electricblue-500 */
  --color-primary-txt: #3324c4; /* electricblue-500 */
  --color-primary-txt-secondary: #4e3fdc; /* electricblue-400 */
  --color-primary-bg: #d9d6f7; /* electricblue-50 */
  --color-primary-bg-hover: #c5c0f4; /* electricblue-100 */
  --color-primary-btn: #3324c4; /* electricblue-500 */
  --color-primary-btn-hover: #281c99; /* electricblue-600 */
  --color-primary-btn-focus: #3324c4; /* electricblue-500 */
  --color-primary-btn-disabled: #7a6ee7; /* electricblue-200*/

  .main-container {
    @apply bg-default-bg;
  }
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenirnext/AvenirNext-UltraLight.woff2") format("woff2"),
    url("../fonts/avenirnext/AvenirNext-UltraLight.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenirnext/AvenirNext-Thin.woff2") format("woff2"),
    url("../fonts/avenirnext/AvenirNext-Thin.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenirnext/AvenirNext-Light.woff2") format("woff2"),
    url("../fonts/avenirnext/AvenirNext-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenirnext/AvenirNext-Regular.woff2") format("woff2"),
    url("../fonts/avenirnext/AvenirNext-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenirnext/AvenirNext-Regular-Italic.woff2") format("woff2"),
    url("../fonts/avenirnext/AvenirNext-Regular-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenirnext/AvenirNext-Medium.woff2") format("woff2"),
    url("../fonts/avenirnext/AvenirNext-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenirnext/AvenirNext-DemiBold.woff2") format("woff2"),
    url("../fonts/avenirnext/AvenirNext-DemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenirnext/AvenirNext-Bold.woff2") format("woff2"),
    url("../fonts/avenirnext/AvenirNext-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenirnext/AvenirNext-Bold.woff2") format("woff2"),
    url("../fonts/avenirnext/AvenirNext-Bold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNext";
  src: url("../fonts/avenirnext/AvenirNext-Heavy.woff2") format("woff2"),
    url("../fonts/avenirnext/AvenirNext-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
