$topbar_height: 70px;
$topbar_logo_width: 235px;
$topbar_logo_width_small: 100px;

.topbar-container {
  height: $topbar_height;
  background-color: $background-nav;
  width: 100%;
  display: flex;
  justify-content: flex-start;

  .topbar-logo {
    display: flex;
    background-color: $primary;
    width: $topbar_logo_width;
    @media (max-width: 1024px) {
      width: $topbar_logo_width_small;
    }
    align-items: center;

    p {
      font-size: 22px;
      font-family: AvenirNext-Bold;
      letter-spacing: 1.05px;
      color: white;
      margin: 0 15px 0 0;
      @media (max-width: 1024px) {
        display: none;
      }
    }

    img {
      margin: 0 10px 0;
      max-height: 0.9 * $topbar_height;
    }
  }

  .topbar-content {
    justify-content: flex-end;
    width: 100%;
  }
}