
#video-detail-page {
  .breadcrumb-container {
    @media (max-width: 768px) {
      margin: 25px 20px 0 20px;
    }
  }
  .mobile-on {
    text-align: center;
    .views {
      display: inline-flex;
    }
  }
  .mobile-row{
    @media (max-width: 768px) {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    @media (min-width: 767px) {
      display: flex;
    }
  }
  .story-media {
    height: 80vh;
    max-width: 45vh;
    @media (max-width: 1200px) {
      height: auto;
      max-width: 30vh;
    }
    @media (max-width: 992px) {
      height: auto;
      max-width: 25vh;
    }
    @media (max-width: 768px) {
      height: auto;
      padding: 0;
      max-width: 30vh;
    }
    video {
      height: inherit;
      max-width: inherit;
    }
  }
  .story-analytics {
    contain: content;
    height: 80vh;
    @media (max-width: 900px) {
      overflow: scroll;
    }
    .icon {
      margin-left: 5px;
      fill: $primary;
    }
    .card {
      border: none;
      border-radius: 0px;
      height: 100%;
    }
  }
  .chart-container {
    @media (max-width: 768px) {
      display: none;
    }
    @media (max-width: 900px) {
      height: 100%;
    }
    height: 45%;
    .empty-chart-placeholder {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .no-data-container{
    opacity: 0.5;
    }
    #story-performance-chart{
      height: inherit;
    }
  }

  .map-container {
    background-color: #e0e0e0;
    position: relative;
    margin: 0px 25px 25px 25px;
    height: 45%;

    @media (max-width: 768px) {
      display: none;
    }

    @media (max-width: 900px) {
      height: 100%;
    }

    #story-displays-map {
      height: 100%;
    }

    .empty-map-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .error-message {
        padding: 20px;
        background-color: rgba(255, 255, 255, 0.5);
        .fa {
          color: $danger;
          margin-right: 15px;
        }
        .text {
          color: $darker;
        }
      }
    }

    .overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0,0,0,0.4);
      z-index: 1;
      .text {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary;
        span {
          background: rgba(255,255,255, 0.8);
          padding: 15px 20px;
        }
      }
    }
  }

  .story-metrics {
    width: 100%;
    justify-content: flex-end;

    label {
      font-size: 13px;
      text-transform: uppercase;
      margin: auto;
      padding: 0px 10px;
    }
  }

  .story-date, .story-title {
    font-size: 15px;
    padding: 3px 0px;
  }

  .story-date {
    font-weight: bold;
  }
}

.story-show-top-mobile {
  .date {
    font-size: 0.6em;
  }
}

.story-show-content-mobile {
  gap: 0.4em;

  .icon-container {
    font-size: 0.6em;
    text-transform: uppercase;
    color: $primary;
    font-family: "AvenirNext-Bold";

    svg {
      width: 1.4em;
    }
  }
  video {
    margin-top: 10px;
  }
}

.story-name-breadcrumb {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 8em;
  display: inline-block;
}
