.dropdown-container {
  position: relative;
  display: inline-block;
  min-height: 200px;
  .dropbtn {
    display: flex;
    align-items: flex-start;
    background-color: $background-nav;
    color: $mediumdark;
    padding: 10px 0 10px 15px;
    border: none;
    cursor: pointer;
    min-width: 250px;
    outline: none;
    text-align: left;
    font-size: 18px;
    .label {
      align-self: center;
      font-weight: bold;
      font-family: $bold;
    }
    .label-icon {
      margin-left: auto;
      margin-right: 20px;
      align-self: center;
    }
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
    &:disabled+.dropdown-menu {
        display: none;
    }
  }
  .dropdown-menu {
    border-radius: 0;

    .dropdown-item {
      display: flex !important;
      color: $mediumdark;
      padding: 20px;
      text-decoration: none;
      display: block;
      text-align: left;
      font-family: $regular;
      font-size: 16px;
      .item-content {
        display: flex;
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        .icon {
          width: 20px;
          margin-right: 15px;
          fill: $mediumdark;
        }
      }
      &:hover {
        color: $primary;
        background-color: $background-nav-active;
        border-left: solid 4px $primary;
        .item-content {
          margin-left: -4px;
        }
      }
      a {
        text-decoration: none;
      }
    }
  }
  &:hover .dropdown-menu {
    display: block;
  }
}
