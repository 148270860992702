.litepicker {
  --litepicker-is-start-color-bg: var(--color-primary) !important;
  --litepicker-is-in-range-color: var(--color-primary-bg) !important;
  --litepicker-is-end-color-bg: var(--color-primary) !important;
}

.container__main {
  margin-top: 5px;
}

.month-item-name,
.month-item-year {
  @apply text-base;
}

body.theme-displays .litepicker {
  font-family: "Montserrat" !important;

  .container__months {
    @apply rounded-md;
  }
}

body.theme-riders .litepicker {
  font-family: "AvenirNext" !important;

  .container__months {
    @apply rounded-xl;
  }
}
