#channels-login-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: inherit;
  background-image: url("/images/phenix_channels/login-channels.jpg");
  background-size: cover;
  background-color: rgba(40, 28, 153, 0.41);
  background-blend-mode: overlay;

  .welcome-msg {
    h2, h3 {
      color: white;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .channels-logo {
    width: 182px;
    height: 153px;
    margin-bottom: 50px;
  }

  .input-login-container {
    margin: 10px 0px 10px 0px;

    flex-direction: column;
    align-items: start;

    .input-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .input-login {
      color: white;
      background-color: rgba(255, 255, 255, 0.35);
      width: 350px;

      &::placeholder {
        color: color(white shade(20%));
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.40);
      }

      &:focus {
        background-color: rgba(255, 255, 255, 0.50);
      }
    }

    .error-input {
      width: 100%;
    }

    i {
      color: white;
    }
  }

 .input-login-container, .reset-password-container {

    .help-block {
      padding-top: 2px;
      text-align: left;
      font-size: 18px;
      color: $danger;
    }
  }

  .forget-password {
    align-self: flex-end;
    margin-bottom: 25px;

    a {
      color: white;
      opacity: 0.8;
      font-size: 15px;
    }
  }

  .login-submit {
    background-color: $primary;
    border: 0;
    font-size: 20px;
    border-radius: 25px;
    padding: 10px 40px 10px 40px;

    &:hover {
      background-color: lighten($primary, 10%);
    }
  }

  .reset-password-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;

    a {
      margin-top: 10px;
      color: white;

      &:hover {
        color: color(white shade(30%));
      }
    }
  }
}
