.link {
  a {
    color: $lighter;
    text-decoration: none;
    .link-icon {
      margin-right: 10px;
      font-size: 16px;
    }
    .link-text {
      font-family: $medium;
      font-size: 16px;
    }
    &:hover {
      color: lighten($light, 20%);
      text-decoration: none;
    }
  }
  a label {
    cursor: pointer;
  }
}